import React from 'react';

import FormGroup from './FormGroup';
import Input from './Input';
import { Props as InputProps } from './Input';

interface Props extends Omit<InputProps, 'ref'> {
  label?: string;
  required: boolean;
  inline: boolean;
  errorMessage?: string | null | false;
}

class InputFormGroup extends React.Component<Props> {
  static defaultProps = {
    required: false,
    inline: false,
  };

  render() {
    const { label, inline, errorMessage, ...inputProps } = this.props;

    return (
      <FormGroup
        label={label}
        required={inputProps.required}
        inline={inline}
        errorMessage={errorMessage}
      >
        {({ inputId }) => (
          <Input {...inputProps} id={inputId} invalid={errorMessage != null} />
        )}
      </FormGroup>
    );
  }
}

export default InputFormGroup;
