import React from 'react';
import ReactDocumentTitle from 'react-document-title';

interface Props {
  title?: string | null;
  children: React.ReactChild | null;
}

const DocumentTitle: React.FC<Props> = (props) => {
  const title =
    props.title != null && props.title.length > 0
      ? `${props.title} | Frere-Jones Type`
      : 'Frere-Jones Type';

  return (
    <ReactDocumentTitle title={title}>{props.children}</ReactDocumentTitle>
  );
};

DocumentTitle.displayName = 'Core.DocumentTitle';

export default DocumentTitle;
