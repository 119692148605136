import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import DocumentTitle from './DocumentTitle';
import Loader from './Loader';
import { PageQuery } from 'client/graphql/types/operations';
import NotFound from './NotFound';
import MarkdownContentWrapper from './MarkdownContentWrapper';

const PAGE_QUERY = gql`
  query PageQuery($slug: String!) {
    page(slug: $slug) {
      id
      title
      content
    }
  }
`;

interface Props {
  slug: string;
  className?: string;
}

export const PageContent: React.FC<Props> = (props) => {
  return (
    <Query<PageQuery> query={PAGE_QUERY} variables={{ slug: props.slug }}>
      {({ loading, data }) => {
        if (loading) {
          return <Loader />;
        }

        if (data == null || data.page == null) {
          return <NotFound />;
        }

        return (
          <DocumentTitle title={data.page.title}>
            <div className={props.className}>
              <MarkdownContentWrapper content={data.page.content} />
            </div>
          </DocumentTitle>
        );
      }}
    </Query>
  );
};

PageContent.displayName = 'Shared.PageContent';

export default PageContent;
