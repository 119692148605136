import './Select.scss';

import React from 'react';
import classNames from 'classnames';

type HTMLSelectElementProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>;

export interface Props extends HTMLSelectElementProps {
  invalid: boolean;
  testID?: string;
  children: React.ReactNode;
}

class Select extends React.Component<Props> {
  static defaultProps = {
    invalid: false,
  };

  render() {
    const { invalid, testID, children, ...selectProps } = this.props;

    return (
      <div
        className={classNames('Select', {
          'is-invalid': invalid,
        })}
      >
        <select {...selectProps} data-test-id={testID}>
          {children}
        </select>
      </div>
    );
  }
}

export default Select;
