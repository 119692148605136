import './ColophonWrapper.scss';

import React from 'react';

interface Props {
  children: React.ReactNode;
}

class ColophonWrapper extends React.PureComponent<Props> {
  render() {
    const { children } = this.props;
    return <div className="ColophonWrapper">{children}</div>;
  }
}

export default ColophonWrapper;
