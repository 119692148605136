import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { uniq, compact, isEqual } from 'lodash-es';

import {
  FontFaceDeclarationsQuery,
  FontFaceDeclarationsQueryVariables,
} from 'client/graphql/types/operations';

const FONT_FACE_DECLARATIONS_QUERY = gql`
  query FontFaceDeclarationsQuery($ids: [Int!]!) {
    faces(ids: $ids) {
      id
      cssDeclaration
    }
  }
`;

interface Props {
  ids: Array<number | null>;
  onLoad?(): void;
  children?(props: { loading: boolean }): React.ReactNode;
}

interface State {
  ids: number[];
  faces: FontFaceDeclarationsQuery['faces'];
}

class SharedFontFaceDeclarations extends React.PureComponent<Props, State> {
  state: State = { ids: normalizeIds(this.props.ids), faces: [] };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.ids !== prevProps.ids) {
      const ids = normalizeIds(this.props.ids);

      if (isEqual(ids, this.state.ids)) {
        // If the "ids" prop has changed, but the normalized ids are the same as
        // the current set of ids, immediately call the "onLoad" function.
        if (typeof this.props.onLoad === 'function') {
          this.props.onLoad();
        }
      } else {
        this.setState({ ids });
      }
    }

    if (this.state.faces !== prevState.faces) {
      if (typeof this.props.onLoad === 'function') {
        this.props.onLoad();
      }
    }
  }

  render() {
    const { ids, faces } = this.state;
    const { children } = this.props;

    return (
      <Query<FontFaceDeclarationsQuery, FontFaceDeclarationsQueryVariables>
        query={FONT_FACE_DECLARATIONS_QUERY}
        variables={{ ids }}
        fetchPolicy="no-cache"
        onCompleted={(data) => {
          if (Array.isArray(data.faces)) {
            // The faces are stored in the state instead of being used directly
            // so that "onLoad" can be called after the faces are loaded _and_
            // rendered.
            this.setState({ faces: data.faces });
          }
        }}
      >
        {({ loading }) => {
          return (
            <>
              {children && children({ loading })}

              {faces.map((face) => (
                <style key={face.id} type="text/css">
                  {face.cssDeclaration}
                </style>
              ))}
            </>
          );
        }}
      </Query>
    );
  }
}

const normalizeIds = (ids: Array<number | null>): Array<number> => {
  return compact(uniq(ids)).sort();
};

export default SharedFontFaceDeclarations;
