import React, { createRef } from 'react';
import classNames from 'classnames';

type HTMLInputElementProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export interface Props extends Omit<HTMLInputElementProps, 'ref'> {
  type?: string;
  required?: boolean;
  invalid?: boolean;
  testID?: string;
  // This should only be used for class names that need to be added for third
  // party libraries
  className?: string;
}

class Input extends React.PureComponent<Props> {
  static defaultProps = {
    type: 'text',
    required: false,
    invalid: false,
  };

  node = createRef<HTMLInputElement>();

  focus() {
    if (this.node.current != null) {
      this.node.current.focus();
    }
  }

  select() {
    if (this.node.current != null) {
      this.node.current.select();
    }
  }

  render() {
    const { className, invalid, testID, ...inputProps } = this.props;

    return (
      <input
        {...inputProps}
        ref={this.node}
        className={classNames(
          'Input',
          {
            'is--invalid': invalid,
          },
          className,
        )}
        data-test-id={testID}
      />
    );
  }
}

export default Input;
