import React from 'react';
import { connect } from 'react-redux';

import { State } from 'modules';

type ProvidedProps = {
  notifications: State['notifications'];
};

export function Notifications(props: ProvidedProps) {
  const { notifications } = props;

  if (notifications.length === 0) {
    return null;
  }

  return (
    <div className="flash-message alert alert-success active">
      {notifications[notifications.length - 1].content}
    </div>
  );
}

export default connect((state: State) => {
  return {
    notifications: state.notifications,
  };
})(Notifications);
