import gql from 'graphql-tag';
import { DataProxy } from 'apollo-cache';

import { CurrentUserQuery } from 'client/graphql/types/operations';

export const USER_ATTRIBUTES = gql`
  fragment UserAttributes on User {
    id
    email
    name
    organization
    organizationRole
  }
`;

export const CURRENT_USER_QUERY = gql`
  query CurrentUserQuery {
    currentUser {
      ...UserAttributes
    }
  }

  ${USER_ATTRIBUTES}
`;

export function updateCache(
  cache: DataProxy,
  data: CurrentUserQuery['currentUser'],
) {
  const cached = cache.readQuery<CurrentUserQuery>({
    query: CURRENT_USER_QUERY,
  });

  if (cached != null && cached.currentUser != null) {
    cache.writeQuery<CurrentUserQuery>({
      query: CURRENT_USER_QUERY,
      data: {
        __typename: 'Query',
        currentUser: {
          ...cached.currentUser,
          ...data,
        },
      },
    });
  } else {
    cache.writeQuery<CurrentUserQuery>({
      query: CURRENT_USER_QUERY,
      data: {
        __typename: 'Query',
        currentUser: data,
      },
    });
  }
}

export type { CurrentUserQuery };
