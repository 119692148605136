import './Loader.scss';
import React from 'react';

import classNames from 'classnames';

// Only show indicator after a delay to prevent users with faster connections
// from seeing a "flash of loading component"
const INDICATOR_DELAY = 300; // ms

interface Props {
  fullPage: boolean;
  overlay: boolean;
  size: string;
  strokeWidth: string;
  text?: string;
}

interface State {
  pastDelay: boolean;
}

class Loader extends React.PureComponent<Props, State> {
  static defaultProps = {
    fullPage: false,
    overlay: false,
    size: '32px',
    strokeWidth: '3px',
  };

  delayTimeoutId?: number;
  state = { pastDelay: false };

  componentDidMount() {
    this.delayTimeoutId = window.setTimeout(
      () => this.setState({ pastDelay: true }),
      INDICATOR_DELAY,
    );
  }

  componentWillUnmount() {
    if (this.delayTimeoutId != null) {
      window.clearTimeout(this.delayTimeoutId);
    }
  }

  render() {
    const { fullPage, overlay, size, strokeWidth, text } = this.props;
    const { pastDelay } = this.state;

    return (
      <div
        className={classNames('Loader', {
          'Loader--fullPage': fullPage,
          'Loader--overlay': overlay,
        })}
      >
        {pastDelay && (
          <>
            <div
              className="Loader-indicator"
              style={{
                fontSize: size,
                borderWidth: strokeWidth,
              }}
            />

            {text && <div className="Loader-text">{text}</div>}
          </>
        )}
      </div>
    );
  }
}

export default Loader;
