import './ContentWrapper.scss';

import React from 'react';
import classNames from 'classnames';

interface Props {
  children: React.ReactNode;
  narrow: boolean;
  inline: boolean;
}

class ContentWrapper extends React.PureComponent<Props> {
  static defaultProps = {
    narrow: false,
    inline: false,
  };

  render() {
    const { children, narrow, inline } = this.props;

    return (
      <div
        className={classNames(
          'ContentWrapper',
          {
            'ContentWrapper--narrow': narrow,
          },
          {
            'ContentWrapper--inline': inline,
          },
        )}
      >
        {children}
      </div>
    );
  }
}

export default ContentWrapper;
