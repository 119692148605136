import './MarkdownContentWrapper.scss';

import React from 'react';

interface Props {
  content?: string | null;
}

const MarkdownContentWrapper: React.FC<Props> = (props) => {
  if (props.content == null) {
    return null;
  }

  return (
    <div
      className="MarkdownContentWrapper"
      dangerouslySetInnerHTML={{ __html: props.content }}
    />
  );
};

export default MarkdownContentWrapper;
