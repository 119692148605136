import styles from './Checkbox.module.scss';

import React from 'react';

type HTMLInputElementProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

interface Props extends HTMLInputElementProps {
  testID?: string;
}

const Checkbox: React.FC<Props> = (props) => {
  const { testID, ...inputProps } = props;

  return (
    <input
      {...inputProps}
      className={styles.input}
      type="checkbox"
      data-test-id={testID}
    />
  );
};

export default Checkbox;
