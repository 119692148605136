import styles from './CheckboxField.module.scss';

import React from 'react';

import Checkbox from './Checkbox';

type HTMLInputElementProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

interface Props extends Omit<HTMLInputElementProps, 'ref'> {
  testID?: string;
  children: React.ReactNode;
}

class CheckboxField extends React.PureComponent<Props> {
  render() {
    const { children, ...inputProps } = this.props;

    return (
      <label className={styles.container}>
        <Checkbox {...inputProps} />

        <span className={styles.label}>{children}</span>
      </label>
    );
  }
}

export default CheckboxField;
