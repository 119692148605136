import React from 'react';
import { Link, RouteComponentProps } from '@reach/router';

import ContentWrapper from './ContentWrapper';

interface Props extends RouteComponentProps {}

const NotFound: React.FC<Props> = () => {
  return (
    <ContentWrapper>
      <h1 className="heading-1">Page not found!</h1>

      <p>
        We&rsquo;re sorry, but the page you&rsquo;re looking for cannot be
        found.
      </p>

      <p>
        Head back <Link to="/">home</Link>.
      </p>
    </ContentWrapper>
  );
};

export default NotFound;
