import './FormGroup.scss';

import React from 'react';
import { uniqueId } from 'lodash-es';
import classNames from 'classnames';

type Children =
  | React.ReactElement
  | ((options: { inputId: string }) => React.ReactNode);

interface Props {
  label?: string;
  required: boolean;
  inline: boolean;
  errorMessage?: string | null | false;
  hint?: string;
  children: Children;
}

class FormGroup extends React.Component<Props> {
  static defaultProps = {
    inline: false,
    required: false,
  };

  inputId = uniqueId('input');

  render() {
    const { inline, label, hint, errorMessage, children } = this.props;

    return (
      <div
        className={classNames('FormGroup', {
          'FormGroup--inline': inline,
          'is-invalid': errorMessage,
        })}
      >
        <label className="FormGroup-label" htmlFor={this.inputId}>
          {label}
        </label>

        {typeof children === 'function'
          ? children({ inputId: this.inputId })
          : children}

        {hint != null && <div className="FormGroup-hint">{hint}</div>}

        {errorMessage && <div className="FormGroup-error">{errorMessage}</div>}
      </div>
    );
  }
}

export default FormGroup;
