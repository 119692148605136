import React from 'react';
import { Location } from '@reach/router';

interface Props {
  location: {
    hash: string;
  };
}

/**
 * Scrolls to the element with an ID which matches the current location.hash
 * (if any). Useful for restoring scroll position after reload on a page with
 * anchors that scroll to specific locations on the page.
 *
 * It also "fixes" in-page links in Safari which don't seem to work when used in
 * conjunction with React Router.
 */
class ScrollToElement extends React.PureComponent<Props> {
  componentDidMount() {
    this.handleHashChange();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.location.hash !== prevProps.location.hash) {
      this.handleHashChange();
    }
  }

  handleHashChange = () => {
    const hash = this.props.location.hash.substr(1);

    if (hash.length > 0) {
      if ('scrollRestoration' in history) {
        history.scrollRestoration = 'manual';
      }

      const target = document.querySelector(`#${hash}`);

      if (target != null) {
        target.scrollIntoView();
      }
    }
  };

  render() {
    return null;
  }
}

const ScrollToElementWithLocation: React.FC = () => {
  return (
    <Location>
      {({ location }) => {
        return <ScrollToElement location={location} />;
      }}
    </Location>
  );
};

export default ScrollToElementWithLocation;
