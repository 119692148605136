declare global {
  interface Window {
    Stripe?(apiKey: string): stripe.Stripe;
  }
}

import React, { useEffect, useState } from 'react';
import { StripeProvider as ExternalStripeProvider } from 'react-stripe-elements';

interface Props {
  apiKey: string;
  children: React.ReactNode;
}

export const StripeProvider: React.FC<Props> = (props) => {
  const { apiKey, children } = props;

  const [stripe, setStripe] = useState<stripe.Stripe | null>(null);

  useEffect(() => {
    if (window.Stripe != null) {
      setStripe(window.Stripe(apiKey));
    } else {
      const scriptTag = document.querySelector('#stripe-js');

      if (scriptTag != null) {
        scriptTag.addEventListener('load', () => {
          if (window.Stripe != null) {
            setStripe(window.Stripe(apiKey));
          }
        });
      } else {
        throw new Error('Stripe script tag is missing from the document.');
      }
    }
  }, [apiKey]);

  return (
    <ExternalStripeProvider stripe={stripe}>{children}</ExternalStripeProvider>
  );
};

StripeProvider.displayName = 'Core.StripeProvider';

export default StripeProvider;
