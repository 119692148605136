import './ColophonColumn.scss';

import React from 'react';
import classNames from 'classnames';

interface Props {
  children: React.ReactNode;
  label?: string;
  alignSelf: string;
  inverted: boolean;
}

class ColophonColumn extends React.PureComponent<Props> {
  static defaultProps = {
    alignSelf: 'flex-start',
    inverted: false,
  };

  render() {
    const { children, label, alignSelf, inverted } = this.props;
    return (
      <div
        className={classNames('ColophonColumn', {
          'ColophonColumn--inverted': inverted,
        })}
        style={{ alignSelf }}
      >
        {label && <div className="ColophonColumn-label">{label}</div>}
        {children}
      </div>
    );
  }
}

export default ColophonColumn;
