import './Button.scss';

import React from 'react';
import classNames from 'classnames';

type HTMLButtonElementProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

interface Props extends HTMLButtonElementProps {
  acting: boolean;
  primary: boolean;
  secondary: boolean;
  testID?: string;
  textLink: boolean;
}

class Button extends React.Component<Props> {
  static defaultProps = {
    type: 'button',
    disabled: false,
    acting: false,
    primary: false,
    secondary: false,
    textLink: false,
  };

  render() {
    const {
      className,
      acting,
      disabled,
      primary,
      secondary,
      textLink,
      testID,
      children,
      ...buttonProps
    } = this.props;

    return (
      <button
        {...buttonProps}
        className={classNames(
          'Button',
          {
            'is-acting': acting,
            'Button--primary': primary,
            'Button--secondary': secondary,
            'Button--textLink': textLink,
          },
          className,
        )}
        disabled={acting || disabled}
        data-test-id={testID}
      >
        {children}
      </button>
    );
  }
}

export default Button;
