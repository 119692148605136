import React from 'react';
import { Location, WindowLocation } from '@reach/router';

interface Props {
  location: WindowLocation;
}

class ScrollToTop extends React.Component<Props> {
  componentDidUpdate(prevProps: Props) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return null;
  }
}

const ScrollToTopWithLocation: React.FC = () => {
  return (
    <Location>
      {({ location }) => {
        return <ScrollToTop location={location} />;
      }}
    </Location>
  );
};

export default ScrollToTopWithLocation;
